import { Component, OnInit, ViewChild, ElementRef } from "@angular/core";
import { MatDialog, MatDialogRef } from "@angular/material";
import { ConfirmDialogComponent } from "../../components/confirm-dialog/confirm-dialog.component";
import { fromEvent } from "rxjs";
import { debounceTime } from "rxjs/operators";
import { DesignService } from "../../../services/design.service";
import { DesignData } from "../../../interfaces/design";
import { NgxSpinnerService } from "ngx-spinner";
import { Router } from "@angular/router";
import { AppRoutes } from "src/app/app.constants";
import { AlertsService } from "angular-alert-module";
import { CategoryData } from "src/interfaces/category";
import { CategoryService } from "src/services/category.service";
import { UserData } from "src/interfaces/user";
import { CutsService } from "src/services/cuts.service";
import { UserService } from "src/services/user.service";
import { CutsData } from "src/interfaces/cuts";

@Component({
  selector: "app-cuts",
  templateUrl: "./cuts.component.html",
  styles: ["td,th { width: 20%;text-align: center;}select{width:200px;}"],
})
export class CutsComponent implements OnInit {
  dialogRef: MatDialogRef<ConfirmDialogComponent>;
  designs: DesignData[];
  cutsCount = 0;
  totalPages = 0;
  currentPage = 0;
  cuts: CutsData[];
  users: UserData[];
  filter = {
    offset: 0,
    limit: 20,
    search: null,
    order: null,
    orderBy: null,
    userId: null,
    designId: null,
    searchDesign: "",
    dataInceput: null,
    dataSfarsit: null,
  };
  sortTable = {
    model: {
      orderBy: "model",
      order: null,
    },
    year: {
      orderBy: "year",
      order: null,
    },
  };
  @ViewChild("searchRef") searchRef: ElementRef;

  constructor(
    private cutsService: CutsService,
    public dialog: MatDialog,
    private spinner: NgxSpinnerService,
    private router: Router,
    private alerts: AlertsService,
    private userService: UserService,
    private designService: DesignService
  ) {}

  ngOnInit() {
    this.filter = { ...this.cutsService.filter };
    this.spinner.show();
    this.getCutsList();
    this.getUsers();
    this.getDesigns();
  }

  public getUsers() {
    this.userService.getUsers({ limit: 1000 }).subscribe((res) => {
      this.users = res.users;
    });
  }

  public getDesigns() {
    this.designService.getDesignsSelectList().subscribe((res) => {
      this.designs = res.designs;
    });
  }

  public searchDesigns(text) {
    console.log("merg");
    console.log(text);
  }

  public sortList(key: string) {
    this.sortTable[key].order =
      this.sortTable[key].order === "asc" ? "desc" : "asc";
    this.filter.orderBy = key;
    this.filter.order = this.sortTable[key].order;
    if (key === "year") {
      this.sortTable.model.order = null;
    } else {
      this.sortTable.year.order = null;
    }
    this.spinner.show();
    this.getCutsList();
  }

  public getCuts() {
    this.spinner.show();
    // this.getCategories();
    this.getCutsList();
  }

  public dataInceputChanged(event) {
    // console.log(event.value);
    this.filter.offset = 0;
    this.filter.dataInceput = event.value;
    this.getCuts();
  }

  public dataSfarsitChanged(event) {
    // console.log(event.value);
    this.filter.offset = 0;
    this.filter.dataSfarsit = event.value;
    this.getCuts();
  }

  public userChanged() {
    this.filter.offset = 0;
    this.getCuts();
  }

  public designChanged() {
    this.filter.offset = 0;
    this.getCuts();
  }

  private getCutsList() {
    // console.log(this.filter);
    this.cutsService.getCuts(this.filter).subscribe(
      (resp) => {
        this.cuts = resp.cuts;
        this.cutsCount = parseInt(resp.cuts_count, 10);
        this.currentPage =
          Math.ceil(this.filter.offset / this.filter.limit) + 1;
        this.totalPages = Math.ceil(this.cutsCount / this.filter.limit);
        this.spinner.hide();
      },
      () => {
        this.spinner.hide();
      }
    );
  }

  public prevPage() {
    this.filter.offset -= this.filter.limit;
    this.spinner.show();
    this.getCutsList();
  }

  public nextPage() {
    this.filter.offset += this.filter.limit;
    this.spinner.show();
    this.getCutsList();
  }

  public openModalDesign(id: number = null) {
    this.router.navigate([
      AppRoutes.userDashboard,
      AppRoutes.designs,
      id ? id : "new",
    ]);
  }
}
