import { BrowserModule } from "@angular/platform-browser";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { AppRoutingModule } from "./app.routing";

import { AppComponent } from "./app.component";
import { LoginComponent } from "./login/login.component";
import { RegisterComponent } from "./register/register.component";
import { UsersComponent } from "./user-dashboard/users/users.component";
import { CategoriesComponent } from "./user-dashboard/categories/categories.component";
import { UserDashboardComponent } from "./user-dashboard/user-dashboard.component";
import { TopnavComponent } from "./user-dashboard/topnav/topnav.component";
import { SidenavComponent } from "./user-dashboard/sidenav/sidenav.component";
import { DesignsComponent } from "./user-dashboard/designs/designs.component";
import { UserService } from "../services/user.service";
import { HttpModule } from "@angular/http";
import { HttpClientModule, HTTP_INTERCEPTORS } from "@angular/common/http";
import { InterceptorService } from "../services/interceptor.service";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { MatDialogModule } from "@angular/material/dialog";
import {
  MatDatepicker,
  MatDatepickerModule,
  MatDialog,
  MatFormFieldModule,
  MatNativeDateModule,
} from "@angular/material";
import { ConfirmDialogComponent } from "./components/confirm-dialog/confirm-dialog.component";
import { CreateUserComponent } from "./components/create-user/create-user.component";
import { CategoryService } from "../services/category.service";
import { CreateCategoryComponent } from "./components/create-category/create-category.component";
import { DesignService } from "../services/design.service";
import { FileSelectDirective } from "ng2-file-upload";
import { NgxSpinnerModule } from "ngx-spinner";
import { ConfirmImgComponent } from "./components/confirm-image/confirm-image.component";
import { ViewItemComponent } from "./components/view-item/view-item.component";
import { AlertsModule } from "angular-alert-module";
import { DesignComponent } from "./user-dashboard/design/design.component";
import { QueryService } from "src/services/query.service";
import { NgSelectModule } from "@ng-select/ng-select";
import { SubCategoriesComponent } from "./user-dashboard/sub-categories/sub-categories.component";
import { NgxSortableModule } from "ngx-sortable";
import { CutsComponent } from "./user-dashboard/cuts/cuts.component";

export const httpInterceptorProviders = [
  { provide: HTTP_INTERCEPTORS, useClass: InterceptorService, multi: true },
];
@NgModule({
  declarations: [
    AppComponent,
    LoginComponent,
    UserDashboardComponent,
    TopnavComponent,
    SidenavComponent,
    RegisterComponent,
    UsersComponent,
    CategoriesComponent,
    SubCategoriesComponent,
    DesignsComponent,
    DesignComponent,
    ConfirmDialogComponent,
    ConfirmImgComponent,
    ViewItemComponent,
    CreateUserComponent,
    CreateCategoryComponent,
    FileSelectDirective,
    CutsComponent,
  ],
  imports: [
    BrowserModule,
    FormsModule,
    AppRoutingModule,
    HttpModule,
    HttpClientModule,
    BrowserAnimationsModule,
    MatDialogModule,
    ReactiveFormsModule,
    NgxSpinnerModule,
    NgxSortableModule,
    NgSelectModule,
    AlertsModule.forRoot(),
    MatDatepickerModule,
    MatFormFieldModule,
    MatNativeDateModule,
  ],
  providers: [
    HttpClientModule,
    httpInterceptorProviders,
    UserService,
    CategoryService,
    QueryService,
    DesignService,
    MatDialog,
  ],
  bootstrap: [AppComponent],
  entryComponents: [
    ConfirmDialogComponent,
    ConfirmImgComponent,
    CreateUserComponent,
    CreateCategoryComponent,
    ViewItemComponent,
  ],
})
export class AppModule {}
