import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { environment } from "../environments/environment";
import { DesignData } from "../interfaces/design";
import { QueryCustom } from "src/interfaces/query";
import { QueryService } from "./query.service";
import { Buffer } from "buffer";

@Injectable({
  providedIn: "root",
})
export class CutsService {
  filter = {
    offset: 0,
    limit: 10,
    search: null,
    order: null,
    orderBy: null,
    userId: null,
    designId: null,
    searchDesign: "",
    dataInceput: null,
    dataSfarsit: null,
  };

  constructor(private http: HttpClient, private queryService: QueryService) {}

  public getCuts(query: QueryCustom): Observable<any> {
    const query_url = this.queryService.encodeQueryData(query);
    return this.http.get(`${environment.api_url}api/cuts?${query_url}`);
  }
}
